<template>
	<div class="animated fadeIn">
		<carbon-dashboard />
		<project-kpis />
		<!-- <evolution-dashboard /> -->
		<budget-dashboard />
	</div>
</template>

<script>
import CarbonDashboard from './dashboard/CarbonDashboard';
import BudgetDashboard from './dashboard/BudgetDashboard';
import EvolutionDashboard from './dashboard/EvolutionDashboard';
import ProjectKpis from './dashboard/ProjectKPIs';
import languageMessages from '@/mixins/languageMessages';

import { store } from '@/store';

export default {
	name: 'Dashboard',
	components: {
		CarbonDashboard,
		BudgetDashboard,
		EvolutionDashboard,
		ProjectKpis
	},
	mixins: [languageMessages]
};
</script>

<style>
/* IE fix */
#card-chart-01,
#card-chart-02 {
	width: 100% !important;
}

.white {
	height: 24px;
	background-color: 'white';
}
</style>
